module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","stylesPath":"/opt/build/repo/src/cms/styles.css","manualInit":true,"enableIdentityWidget":"true","publicPath":"admin","htmlTitle":"Управление на съдържанието"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-43524222-1","head":false,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Детска Млечна Кухня 'Пълно Коремче'","short_name":"Пълно Коремче","start_url":"/","background_color":"#FFF","theme_color":"#FFF","display":"fullscreen","icon":"src/images/manifest-icon-512.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ee709b1b1bd8001d18afbdd8ca9a0844"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
